import styles from './interval-selector-button.module.css'
import classNames from 'classnames/bind'
import { ChartInterval } from '@/features/chart/types'
import { FC } from 'react'
import { useChartInterval, useIsFetchingHistoricalTokenPrice } from '@/features/chart/hooks'

const cx = classNames.bind(styles)

interface IntervalSelectorButtonProps {
  label: string
  value: ChartInterval
}

export const IntervalSelectorButton: FC<IntervalSelectorButtonProps> = ({ label, value }) => {
  const { chartInterval, setChartInterval } = useChartInterval()
  const { isFetching } = useIsFetchingHistoricalTokenPrice()

  const isSelected = chartInterval === value

  const handleClick = () => {
    if (isSelected || isFetching) return
    setChartInterval(value)
  }

  return (
    <button className={cx('button', { button__selected: isSelected })} onClick={handleClick}>
      {label}
    </button>
  )
}
