export const getElementMaxWidth = (element: HTMLElement) => {
  const parent = element.parentElement

  if (parent == null) return null

  const parentComputedStyle = window.getComputedStyle(parent)
  const paddingLeft = parseFloat(parentComputedStyle.paddingLeft)
  const paddingRight = parseFloat(parentComputedStyle.paddingRight)

  const maxWidth = parent.offsetWidth - paddingLeft - paddingRight

  return maxWidth
}
