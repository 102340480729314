export const SUPERSCRIPT_MAP = {
  '0': '\u2070', // Superscript 0 (⁰)
  '1': '\u00B9', // Superscript 1 (¹)
  '2': '\u00B2', // Superscript 2 (²)
  '3': '\u00B3', // Superscript 3 (³)
  '4': '\u2074', // Superscript 4 (⁴)
  '5': '\u2075', // Superscript 5 (⁵)
  '6': '\u2076', // Superscript 6 (⁶)
  '7': '\u2077', // Superscript 7 (⁷)
  '8': '\u2078', // Superscript 8 (⁸)
  '9': '\u2079', // Superscript 9 (⁹)
} as const

export const SUBSCRIPT_MAP = {
  '0': '\u2080', // Subscript 0 (₀)
  '1': '\u2081', // Subscript 1 (₁)
  '2': '\u2082', // Subscript 2 (₂)
  '3': '\u2083', // Subscript 3 (₃)
  '4': '\u2084', // Subscript 4 (₄)
  '5': '\u2085', // Subscript 5 (₅)
  '6': '\u2086', // Subscript 6 (₆)
  '7': '\u2087', // Subscript 7 (₇)
  '8': '\u2088', // Subscript 8 (₈)
  '9': '\u2089', // Subscript 9 (₉)
} as const
