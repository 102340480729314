import { FC } from 'react'
import styles from './skelleton-loader.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

interface SkelletonLoaderProps {
  className?: string
}

export const SkelletonLoader: FC<SkelletonLoaderProps> = ({ className }) => {
  return (
    <div className={cx('skelleton-container', className)}>
      <div className={cx('skelleton-loader')}></div>
    </div>
  )
}
