import { AlchemyChartInterval, ChartInterval } from '../../types'
import { CHART_INTERVALS } from './chart-intervals'

export const ALCHEMY_CHART_INTERVALS = {
  DAY_1: '1d',
  HOUR_1: '1h',
  MINUTE_5: '5m',
} as const

export const CHAIN_INTERVAL_TO_ALCHEMY_INTERVAL: Record<ChartInterval, AlchemyChartInterval> = {
  [CHART_INTERVALS.ONE_HOUR]: ALCHEMY_CHART_INTERVALS.MINUTE_5,
  [CHART_INTERVALS.ONE_DAY]: ALCHEMY_CHART_INTERVALS.MINUTE_5,
  [CHART_INTERVALS.ONE_WEEK]: ALCHEMY_CHART_INTERVALS.HOUR_1,
  [CHART_INTERVALS.ONE_MONTH]: ALCHEMY_CHART_INTERVALS.DAY_1,
  [CHART_INTERVALS.ONE_YEAR]: ALCHEMY_CHART_INTERVALS.DAY_1,
}
