import { FC, useMemo } from 'react'
import { chartQueryClient } from './query-client'
import { QueryClientProvider } from '@tanstack/react-query'
import { createStore, Provider } from 'jotai'
import { chartStore as chartStoreGlobal } from './store'
import { HydrateChartAtoms } from './hydrate-atoms'

interface ProviderProps {
  children: JSX.Element
  useGlobalStore?: boolean
}

/**
 *
 * @param props.children
 * @param props.useGlobalStore If true, it will use the chart global store, otherwise it will use a store specific for this instance of the chart. False is recommended when using multiple charts in the same page. Default is true.
 * @returns
 */
export const ChartProvider: FC<ProviderProps> = ({ children, useGlobalStore = true }) => {
  const chartStore = useMemo(createStore, [])

  return (
    <QueryClientProvider client={chartQueryClient}>
      <Provider store={useGlobalStore ? chartStoreGlobal : chartStore}>
        <HydrateChartAtoms>{children}</HydrateChartAtoms>
      </Provider>
    </QueryClientProvider>
  )
}
