export const getDecimalPlaces = (value: number, numberOfSignificantDecimals = 4) => {
  if (value >= 10_000_000 || value < 0.000000000000000001) return 0
  if (value >= 1_000) return 2
  if (value >= 1) return 3

  const decimalPart = value.toFixed(18).split('.')[1] || ''
  let decimals = 0
  let significantDecimals = 0
  let reachedNonLeftZeroDecimals = false

  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] !== '0' && !reachedNonLeftZeroDecimals) {
      reachedNonLeftZeroDecimals = true
    }

    decimals++
    if (reachedNonLeftZeroDecimals) significantDecimals++

    if (significantDecimals === numberOfSignificantDecimals) break
  }

  return decimals
}
