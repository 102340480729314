import styles from './lightweight-chart.module.css'
import classNames from 'classnames/bind'
import { AreaSeries, ColorType, createChart, CrosshairMode, LineStyle } from 'lightweight-charts'
import { FC, memo, useEffect, useRef } from 'react'
import { formatChartPrice, getCSSVariable, getElementMaxWidth } from '../../../utils'
import { ChartInterval, LightweightChartProps } from '@/features/chart/types'
import { CHART_INTERVALS } from '@/features/chart/consts'

const cx = classNames.bind(styles)

// TODO -
// * 1. Wrap the chart in a jotai store + tanstack/react-query to manage the data fetching
// * 2. Add buttons to change the time interval of the chart
// *   2.1. Don't allow to change the interval if the chart data is loading
// * 3. Make the fetch from the chart component itself. It receives the token address as prop
// * 4. Show it when data is loading
// * 5. To avoid extra calls, store the data fetched in a global variable
// *  Every time the chartData is loaded, an atomEffect gets it and adds it to the variable
// *  It stores `tokenAddress`, `tokenChain`, `interval`, `data` and `timestamp` of the fetch
// *  When the fetch function is executed, it checks this variable
// *  To know if it's valid, it checks the `timestamp` and the `interval`. If it hasn't passed enough time as to add a new value, it's valid. Otherwise, it needs to fetch this new entry and it's invalid
// 6. Manage fetching extra data. Set an interval at the timeframe of the chart. This adds data forward in time.
// 7. Manage fetching extra past data. Set an interval at the timeframe of the chart. This adds data backward in time.
//   Do 5 when everything else is working. Most important is getting the info for the current token and updating with ned data
//   For 5 will need to detect when the user is scrolling the chart to the right and load more data

export const LightweightChart: FC<LightweightChartProps> = memo(({ data, chartInterval, style }) => {
  const chartColorBackground = getCSSVariable('--chart-color-background')
  const chartColorOnBackground = getCSSVariable('--chart-color-on-background')
  const chartFontText = getCSSVariable('--chart-font-text')
  const chartMinHeight = Number(getCSSVariable('--chart-min-height').replace('px', '') ?? 0)

  const {
    backgroundColor = chartColorBackground,
    textColor = chartColorOnBackground,
    lineColor = '#2962FF',
    areaTopColor = '#2962FF',
    areaBottomColor = 'rgba(41, 98, 255, 0.28)',
    fontFamily = chartFontText,
    fontSize = 15,
  } = style || {}

  const chartRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (chartRef.current == null) return

    const chartMaxWidth = getElementMaxWidth(chartRef.current)

    if (chartMaxWidth == null) return

    const handleResize = () => {
      if (chartRef.current == null) return
      const chartMaxWidth = getElementMaxWidth(chartRef.current)
      if (chartMaxWidth == null) return
      chart.applyOptions({ width: chartMaxWidth })
    }

    const chart = createChart(chartRef.current, {
      width: chartMaxWidth,
      height: chartMinHeight,
      autoSize: true,
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
        fontFamily,
        fontSize,
        attributionLogo: false,
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.1)',
          style: 4,
          visible: true,
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.1)',
          style: 4,
          visible: true,
        },
      },
      timeScale: {
        timeVisible: (
          [CHART_INTERVALS.ONE_HOUR, CHART_INTERVALS.ONE_DAY, CHART_INTERVALS.ONE_WEEK] as ChartInterval[]
        ).includes(chartInterval),
        borderVisible: false,
        ticksVisible: false,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
      rightPriceScale: {
        borderVisible: false,
      },
      crosshair: {
        mode: CrosshairMode.Magnet,
        horzLine: {
          visible: true,
          style: LineStyle.Solid,
          width: 1,
          color: 'rgba(197, 203, 206, 0.3)',
          labelVisible: true,
        },
        vertLine: {
          visible: true,
          style: LineStyle.Solid,
          width: 1,
          color: 'rgba(197, 203, 206, 0.3)',
          labelVisible: true,
        },
      },
    })

    chart.timeScale().fitContent()

    const newSeries = chart.addSeries(AreaSeries, {
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lineType: 0,
      lastPriceAnimation: 2,
      priceFormat: {
        type: 'custom',
        formatter: formatChartPrice,
        minMove: 0.0000000001,
      },
    })
    newSeries.setData(data)

    // chart.subscribeCrosshairMove((param) => {
    //   const hoverData = getCrosshairHoverData(param, chartRef.current!, newSeries)
    // })

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)

      chart.remove()
    }
  }, [data])

  return (
    <div className={cx('chart-container')}>
      <div id="chart" className={cx('chart')} ref={chartRef}></div>
    </div>
  )
})

// export type SeriesDataItemType = SeriesDataItemTypeMap<Time>[keyof SeriesOptionsMap]

// export type ChartHoverData<TDataType extends SeriesDataItemType> = {
//   item: TDataType
//   x: number
//   y: number
//   logicalIndex: Logical
// }

// const isBetween = (num: number, lower: number, upper: number) => num > lower && num < upper

// const getCrosshairHoverData = (param: MouseEventParams<Time>, chartDiv: HTMLDivElement, series: ISeriesApi<any>) => {
//   let newHoverData: ChartHoverData<SeriesDataItemType> | undefined = undefined
//   const logical = param.logical
//   const { x, y } = param.point || {}

//   if (
//     x !== undefined &&
//     isBetween(x, 0, chartDiv.clientWidth) &&
//     y !== undefined &&
//     isBetween(y, 0, chartDiv.clientHeight) &&
//     logical !== undefined
//   ) {
//     const item = param.seriesData.get(series)
//     if (item != null) {
//       newHoverData = { item, x, y, logicalIndex: logical }
//     }
//   }

//   return newHoverData
// }
