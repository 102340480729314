import { UTCTimestamp } from 'lightweight-charts'
import { AlchemyTokenPrice, ChartAreaData } from '../types'

export const alchemyTokenPriceToChartAreaData = (prices: AlchemyTokenPrice[]) => {
  const areaData: ChartAreaData = prices.map((entry) => ({
    value: parseFloat(entry.value),
    time: Math.floor(new Date(entry.timestamp).getTime() / 1000) as UTCTimestamp,
  }))

  return areaData
}
