import { CHART_INTERVALS } from './chart-intervals'

const oneHour = 60 * 60
/**
 * Amount of seconds since the last data point that must pass before we consider the data expired
 */
export const INTERVAL_EXPIRY_TIME_SECONDS = {
  [CHART_INTERVALS.ONE_HOUR]: 60 * 5,
  [CHART_INTERVALS.ONE_DAY]: oneHour,
  [CHART_INTERVALS.ONE_WEEK]: oneHour,
  [CHART_INTERVALS.ONE_MONTH]: oneHour * 24,
  [CHART_INTERVALS.ONE_YEAR]: oneHour * 24,
} as const
