import { stakingPools } from '@/constants/stakingPools'
import { AllowedPool } from '@/ts'

export const getTokenChartInfoFromPid = (pid: AllowedPool) => {
  let poolInfo = stakingPools.find((pool) => pool.PID === pid)!

  const tokenIsLp = poolInfo.lp?.isLpToken

  if (tokenIsLp) {
    poolInfo = stakingPools.find((pool) => pool.PID === poolInfo.lp?.token0Pid)!
  }

  return {
    tokenAddress: poolInfo.contract,
    tokenSymbol: poolInfo.name,
  }
}
