import { useAtomValue } from 'jotai'
import { getHistoricalTokenPriceAtom } from '../state/atoms'

export const useGetTokenHistoricalPrice = () => {
  const data = useAtomValue(getHistoricalTokenPriceAtom)

  return {
    ...data,
    data: data.data?.data || [],
  }
}
