import { useAtom } from 'jotai'
import { chartIntervalAtom } from '../state/atoms'
import { ChartInterval } from '../types'

export const useChartInterval = () => {
  const [chartInterval, setChartInterval] = useAtom(chartIntervalAtom)

  const setChartIntervalSafe = (interval: ChartInterval) => {
    if (interval === chartInterval) return
    setChartInterval(interval)
  }

  return { chartInterval, setChartInterval: setChartIntervalSafe }
}
