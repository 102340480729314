import { GetTokenPriceFromAlchemyParams, HistoricalTokenPriceAlchemyResponse } from '../../types'
import { fetchAlchemy } from './fetch-alchemy'

const HISTORICAL_TOKEN_PRICE_ENDPOINT = 'prices/v1/{{api-token}}/tokens/historical'

/**
 *
 * @param body
 * @returns response
 * Alchemy API has a limit of 300 price calls/hour for free tier
 */
export const getHistoricalTokenPriceFromAlchemy = async (body: GetTokenPriceFromAlchemyParams) => {
  const response = await fetchAlchemy<HistoricalTokenPriceAlchemyResponse>({
    endpoint: HISTORICAL_TOKEN_PRICE_ENDPOINT,
    init: {
      body,
      method: 'POST',
    },
  })

  return response
}
