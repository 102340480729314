import { FC } from 'react'
import '../../../styles/index.css'
import styles from './chart.module.css'
import classNames from 'classnames/bind'
import { ChartProps } from '@/features/chart/types'
import { ChartProvider } from '@/features/chart/state'
import { ChartManager } from '../chart-manager'

const cx = classNames.bind(styles)

interface MainChartProps extends ChartProps {
  useGlobalStore?: boolean
}

/**
 *
 * @param props.useGlobalStore If `true`, it will use the chart global store, otherwise it will use a store specific for this instance of the chart. `false` is recommended when using multiple charts in the same page. `true` is recommended when there's only one chart, since it keeps the state after being destroyed (e.g. when changing the page), and thanks to the data cache, extra API calls are avoided. Default is `true`.
 * @returns
 */
export const Chart: FC<MainChartProps> = ({
  tokenAddress,
  tokenChain,
  tokenSymbol,
  currencySymbol = 'usd',
  style,
  useGlobalStore,
}) => {
  return (
    <ChartProvider useGlobalStore={useGlobalStore}>
      <ChartManager
        tokenAddress={tokenAddress}
        tokenChain={tokenChain}
        tokenSymbol={tokenSymbol}
        currencySymbol={currencySymbol}
        style={style}
      />
    </ChartProvider>
  )
}
