import { SkelletonLoader } from '../skelleton-loader'
import styles from './chart-skelleton-loader.module.css'
import classNames from 'classnames/bind'
import { ChartWaveSkelletonLoader } from '../chart-wave-skelleton-loader'

const cx = classNames.bind(styles)

export const ChartSkelletonLoader = () => {
  const elementArray = [0, 1, 2, 3, 4]

  return (
    <div className={cx('main-container')}>
      <div className={cx('chart-and-price-container')}>
        <div className={cx('chart-container')}>
          <ChartWaveSkelletonLoader height={350} />
        </div>
        <div className={cx('price-container')}>
          {elementArray.map((num) => (
            <div key={num} className={cx('price-element')}>
              <SkelletonLoader />
            </div>
          ))}
        </div>
      </div>

      <div className={cx('time-container')}>
        {elementArray.map((num) => (
          <div key={num} className={cx('time-element')}>
            <SkelletonLoader />
          </div>
        ))}
      </div>
    </div>
  )
}
