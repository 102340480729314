import { SUBSCRIPT_MAP } from '../consts'
import { getDecimalPlaces } from './get-decimal-places'

export const formatChartPrice = (price: number) => {
  const decimals = getDecimalPlaces(price)

  const priceString = price.toFixed(decimals)

  const [intPart, decimalPart] = priceString.split('.')

  if (decimalPart == null) return intPart

  const leadingZerosMatch = decimalPart.match(/^0+/)
  const zeroCount = leadingZerosMatch?.[0].length ?? 0

  if (zeroCount > 4) {
    // Convert to array to cover situations where the number of zeros is greater than 9
    const subscriptZeros = Array.from(zeroCount.toString())
      .map((d) => SUBSCRIPT_MAP[d as keyof typeof SUBSCRIPT_MAP])
      .join('')

    // Extract non-zero digits after leading zeros
    const significantDigits = decimalPart.slice(zeroCount)

    return `${intPart}.0${subscriptZeros}${significantDigits}`
  }

  return priceString
}
