import { useAtom } from 'jotai'
import { tokenAddressAtom } from '../state/atoms'
import { Address } from 'viem'

export const useTokenAddress = () => {
  const [tokenAddress, setTokenAddress] = useAtom(tokenAddressAtom)

  const setTokenAddressSafe = (address: Address) => {
    if (address === tokenAddress) return
    setTokenAddress(address)
  }

  return { tokenAddress, setTokenAddress: setTokenAddressSafe }
}
