const ALCHEMY_API_TOKEN = process.env.REACT_APP_ALCHEMY_API_TOKEN ?? ''
const ALCHEMY_BASE_URL = 'https://api.g.alchemy.com'

export interface BaseRequestInit extends Omit<RequestInit, 'body'> {
  body?: BodyInit | Record<any, any> | null
}

export interface FetchAlchemyParams {
  endpoint: string
  init: BaseRequestInit
}

export const fetchAlchemy = async <T>({ endpoint, init }: FetchAlchemyParams) => {
  if (typeof init.body !== 'undefined' && init.body != null && typeof init.body !== 'string') {
    init.body = JSON.stringify(init.body)
  }

  init.headers = {
    ...init.headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${ALCHEMY_API_TOKEN}`,
  }

  endpoint = endpoint.replace('{{api-token}}', ALCHEMY_API_TOKEN)

  const url = new URL(endpoint, ALCHEMY_BASE_URL)

  return fetch(url, init as RequestInit).then((response) => response.json() as T)
}
