import styles from './chart-manager.module.css'
import classNames from 'classnames/bind'
import { ChartProps } from '@/features/chart/types'
import { FC, useEffect } from 'react'
import { LightweightChart } from '../lightweight-chart'
import { useChartInterval, useGetTokenHistoricalPrice, useTokenAddress, useTokenChain } from '@/features/chart/hooks'
import { IntervalSelector } from '../../chart-customization'
import { ChartSkelletonLoader } from '../../loaders'
import { formatChartPrice } from '@/features/chart/utils'

const cx = classNames.bind(styles)

export const ChartManager: FC<ChartProps> = ({
  tokenAddress,
  tokenChain,
  tokenSymbol,
  currencySymbol = 'usd',
  style,
}) => {
  const { data, isFetching } = useGetTokenHistoricalPrice()
  const { setTokenAddress } = useTokenAddress()
  const { setTokenChain } = useTokenChain()
  const { chartInterval } = useChartInterval()

  useEffect(() => {
    setTokenAddress(tokenAddress)
  }, [tokenAddress])

  useEffect(() => {
    setTokenChain(tokenChain)
  }, [tokenChain])

  return (
    <section className={cx('chart-container')}>
      <div className={cx('chart-top-section')}>
        <h4 className={cx('chart-title')}>
          {tokenSymbol.toUpperCase()}/{currencySymbol.toUpperCase()}
        </h4>

        {!isFetching && data[data.length - 1]?.value != null && (
          <h4 className={cx('chart-title', 'chart-title__price')}>${formatChartPrice(data[data.length - 1].value)}</h4>
        )}
      </div>

      {isFetching ? (
        <ChartSkelletonLoader />
      ) : (
        <LightweightChart data={data} chartInterval={chartInterval} style={style} />
      )}

      <div className={cx('chart-bottom-section')}>
        <IntervalSelector />
      </div>
    </section>
  )
}
