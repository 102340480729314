import { useAtom } from 'jotai'
import { tokenChainAtom } from '../state/atoms'
import { ChartChain } from '../types'

export const useTokenChain = () => {
  const [tokenChain, setTokenChain] = useAtom(tokenChainAtom)

  const setTokenChainSafe = (chain: ChartChain) => {
    if (chain === tokenChain) return
    setTokenChain(chain)
  }

  return { tokenChain, setTokenChain: setTokenChainSafe }
}
