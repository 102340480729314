import { CHART_INTERVALS } from './chart-intervals'

const ONE_HOUR = 1000 * 60 * 60
const ONE_DAY = ONE_HOUR * 24

/**
 * Maps the chart intervals to the number of milisecons we will need chart data for
 */
export const CHART_INTERVAL_TO_MAX_MILISECONDS = {
  [CHART_INTERVALS.ONE_HOUR]: ONE_HOUR,
  [CHART_INTERVALS.ONE_DAY]: ONE_DAY,
  [CHART_INTERVALS.ONE_WEEK]: ONE_DAY * 7,
  [CHART_INTERVALS.ONE_MONTH]: ONE_DAY * 31,
  [CHART_INTERVALS.ONE_YEAR]: ONE_DAY * 365,
}
