import { CHART_INTERVALS } from '@/features/chart/consts'
import { IntervalSelectorButton } from './interval-selector-button'
import styles from './interval-selector.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

export const IntervalSelector = () => {
  return (
    <div className={cx('interval-selector')}>
      <IntervalSelectorButton label={CHART_INTERVALS.ONE_HOUR} value={CHART_INTERVALS.ONE_HOUR} />
      <IntervalSelectorButton label={CHART_INTERVALS.ONE_DAY} value={CHART_INTERVALS.ONE_DAY} />
      <IntervalSelectorButton label={CHART_INTERVALS.ONE_WEEK} value={CHART_INTERVALS.ONE_WEEK} />
      <IntervalSelectorButton label={CHART_INTERVALS.ONE_MONTH} value={CHART_INTERVALS.ONE_MONTH} />
      <IntervalSelectorButton label={CHART_INTERVALS.ONE_YEAR} value={CHART_INTERVALS.ONE_YEAR} />
    </div>
  )
}
