import { formatChartPrice } from '@/features/chart/utils'
import styles from './chart-header.module.css'
import classNames from 'classnames/bind'
import { Time } from 'lightweight-charts'
import { FC } from 'react'

const cx = classNames.bind(styles)

interface ChartHeaderProps {
  value: number
  time: string
}

export const ChartHeader: FC<ChartHeaderProps> = ({ value, time }) => {
  const timeString = new Date(time).toLocaleDateString()
  console.log('time', time)

  return (
    <div className={cx('chart-header')}>
      <h4>${formatChartPrice(value)}</h4>
      <h5>{timeString}</h5>
    </div>
  )
}
