import { ChartLoadingStateMask } from './chart-loading-state-mask'

export const ChartWaveSkelletonLoader = ({ height }: { height: number }) => {
  return (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" fill="none">
      <ChartLoadingStateMask height={height} id={'maskId'} />
      <g mask={`url(#${'maskId'})`}>
        <rect width="100%" height={height} rx="4" fill={`url(#${'maskId'}-gradient)`} />
      </g>
    </svg>
  )
}
