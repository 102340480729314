import classNames from 'classnames/bind'
import { useParams, Navigate } from 'react-router-dom'
import { ALLOWED_POOLS } from 'src/constants'

import { PoolInfo } from '../../components/PoolInfo/PoolInfo'
import styles from './PoolInfoPage.module.scss'
import type { AllowedPool } from '@/ts'
import { Chart, CHART_CHAINS } from '@/features/chart'
import { getTokenChartInfoFromPid } from '@/utils'
import { useMemo } from 'react'
const cx = classNames.bind(styles)

export const PoolInfoPage = () => {
  const { PID } = useParams()

  if (PID == null || !ALLOWED_POOLS.includes(parseInt(PID) as AllowedPool)) {
    return <Navigate to={'/'} />
  }

  const pidNumber = parseInt(PID) as AllowedPool

  const { tokenAddress, tokenSymbol } = useMemo(() => getTokenChartInfoFromPid(pidNumber), [pidNumber])

  return (
    <div className={cx('main__wrapper')}>
      <div className={cx('chart-and-pool-wrapper')}>
        <div className={cx('chart-container')}>
          <Chart tokenAddress={tokenAddress} tokenSymbol={tokenSymbol} tokenChain={CHART_CHAINS.ETH} />
        </div>

        <div className={cx('pool__info__wrapper')}>
          <PoolInfo PID={pidNumber} />
        </div>
      </div>
    </div>
  )
}
