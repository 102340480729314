import { atom } from 'jotai'
import { Address } from 'viem'
import { ChartAreaData, ChartChain, HistoricalTokenPrices, ChartInterval } from '../../types'
import {
  CHAIN_TO_ALCHEMY_ID,
  CHART_INTERVAL_TO_MAX_MILISECONDS,
  INTERVAL_EXPIRY_TIME_SECONDS,
  CHART_INTERVALS,
  CHAIN_INTERVAL_TO_ALCHEMY_INTERVAL,
} from '../../consts'
import { atomWithQuery } from 'jotai-tanstack-query'
import { getHistoricalTokenPriceFromAlchemy } from '../../services'
import { alchemyTokenPriceToChartAreaData } from '../../utils'
import { selectAtom } from 'jotai/utils'
import { atomEffect } from 'jotai-effect'
import { Query, QueryKey } from '@tanstack/query-core'

export const tokenAddressAtom = atom<Address | null>(null)
export const tokenChainAtom = atom<ChartChain | null>(null)
export const chartIntervalAtom = atom<ChartInterval>(CHART_INTERVALS.ONE_YEAR)

interface HistoricalTokenPriceQueryData {
  tokenAddress: Address
  tokenChain: ChartChain
  interval: ChartInterval
  data: ChartAreaData
}

export const getHistoricalTokenPriceAtom = atomWithQuery((get) => {
  const address = get(tokenAddressAtom)
  const chain = get(tokenChainAtom)
  const interval = get(chartIntervalAtom)

  const queryKey = ['token-historical-price', { address, chain, interval }]

  const expiryTime = INTERVAL_EXPIRY_TIME_SECONDS[get(chartIntervalAtom)] * 1000
  const enabled = address != null && chain != null

  return {
    queryKey,
    queryFn: async () => {
      const address = get(tokenAddressAtom)
      const chain = get(tokenChainAtom)

      if (address == null || chain == null) return null

      const interval = get(chartIntervalAtom)
      const alchemyChartInterval = CHAIN_INTERVAL_TO_ALCHEMY_INTERVAL[interval]

      const endTimeDate = new Date()
      const startTimeDate = getStartTime(endTimeDate, interval)

      const endTime = `${endTimeDate.toISOString().slice(0, 19)}Z`
      const startTime = `${startTimeDate.toISOString().slice(0, 19)}Z`

      const response = await getHistoricalTokenPriceFromAlchemy({
        address,
        startTime,
        endTime,
        interval: alchemyChartInterval,
        network: CHAIN_TO_ALCHEMY_ID[chain],
      })

      const data = alchemyTokenPriceToChartAreaData(response.data)

      const returnData: HistoricalTokenPriceQueryData = {
        tokenAddress: address,
        tokenChain: chain,
        interval,
        data,
      }

      return returnData
    },
    refetchInterval: (
      query: Query<HistoricalTokenPriceQueryData | null, Error, HistoricalTokenPriceQueryData | null, QueryKey>
    ) => {
      const data = query.state.data

      if (data == null) return expiryTime

      const lastTimestamp = (data.data.at(-1)?.time as number) ?? 0
      const isTimestampExpired = isChartDataTimestampExpired(lastTimestamp, interval)
      const timestamp = isTimestampExpired ? 1000 * 60 * 5 : expiryTime - (new Date().getTime() - lastTimestamp * 1000)

      // Always wait at least 10 seconds before refetching
      return Math.max(timestamp, 10_000)
    },
    staleTime: (
      query: Query<HistoricalTokenPriceQueryData | null, Error, HistoricalTokenPriceQueryData | null, QueryKey>
    ) => {
      const data = query.state.data

      // Convert to stale 1 second before refetching
      if (data == null) return expiryTime - 1000

      const lastTimestamp = (data.data.at(-1)?.time as number) ?? 0
      const isTimestampExpired = isChartDataTimestampExpired(lastTimestamp, interval)
      const timestamp = isTimestampExpired ? 1000 * 60 * 5 : expiryTime - (new Date().getTime() - lastTimestamp * 1000)

      // Convert to stale 1 second before refetching
      const staleTime = Math.max(timestamp, 10_000) - 1000

      return staleTime
    },
    enabled,
    keepPreviousData: false,
  }
})

export const isFetchingHistoricalTokenPriceAtom = selectAtom(getHistoricalTokenPriceAtom, (data) => data?.isFetching)

// const historicalTokenPriceAtom = selectAtom(getHistoricalTokenPriceAtom, (data) => data.data)

// const historicalTokensPricesAtom = atom<HistoricalTokenPrices>({})

// export const updateHistoricalTokensPricesAtom_Effect = atomEffect((get, set) => {
//   const historicalTokenPrice = get(historicalTokenPriceAtom)

//   if (historicalTokenPrice == null) return

//   const { tokenAddress, tokenChain, interval, data } = historicalTokenPrice

//   const historicalTokensPrices = get.peek(historicalTokensPricesAtom)

//   const key = `${tokenAddress}-${tokenChain}` as `${Address}-${ChartChain}`

//   const lastTimestamp = (data.at(-1)?.time as number) ?? 0
//   const isTimestampExpired = isChartDataTimestampExpired(lastTimestamp, interval)
//   const timestamp = isTimestampExpired ? new Date().getTime() / 1000 + 60 * 5 : lastTimestamp

//   const newHistoricalTokensPrices: HistoricalTokenPrices = {
//     ...historicalTokensPrices,
//     [key]: {
//       ...historicalTokensPrices[key],
//       [interval]: {
//         timestamp,
//         data,
//       },
//     },
//   }

//   set(historicalTokensPricesAtom, newHistoricalTokensPrices)
// })

/**
 * Data of the price of the currently selected token. If the token is invalid, it returns `false`. If the data is expired or there's no data, it returns `null`. Otherwise, it returns the data.
 */
// export const currentTokenHistoricalPriceAtom = atom<ChartAreaData | null | false>((get) => {
//   const tokenAddress = get(tokenAddressAtom)
//   const tokenChain = get(tokenChainAtom)

//   if (tokenAddress == null || tokenChain == null) return false

//   const interval = get(chartIntervalAtom)

//   const key = `${tokenAddress}-${tokenChain}` as `${Address}-${ChartChain}`

//   const historicalTokensPrices = get(historicalTokensPricesAtom)

//   const historicalTokenValues = historicalTokensPrices[key]?.[interval]

//   if (historicalTokenValues == null) return null

//   const { timestamp, data } = historicalTokenValues

//   // --------------
//   // const timestampTimes = getTimestampTimes(data)
//   // const higherDifference = getHihgerDifference(timestampTimes)
//   // const timeString = getTime(higherDifference)
//   // console.log('higherDifference', higherDifference)
//   // console.log('timeString', timeString)
//   // --------------

//   const isDataExpired = isChartDataTimestampExpired(timestamp, interval)

//   if (isDataExpired) return null

//   return data
// })

const isChartDataTimestampExpired = (timestamp: number, interval: ChartInterval) => {
  const now = new Date().getTime() / 1000
  const isDataExpired = now - timestamp > INTERVAL_EXPIRY_TIME_SECONDS[interval]

  return isDataExpired
}

const getStartTime = (endTime: Date, interval: ChartInterval) => {
  const timeToSubstract = CHART_INTERVAL_TO_MAX_MILISECONDS[interval]
  const startTime = new Date(endTime.getTime() - timeToSubstract)
  return startTime
}

// const getTimestampTimes = (data: ChartAreaData) => {
//   let numbers: [number, number][] = []

//   for (let i = 0; i < data.length - 1; i += 2) {
//     const a = data[i]
//     const b = data[i + 1]

//     numbers.push([a.time as number, b.time as number])
//   }

//   return numbers
// }

// const getHihgerDifference = (numbers: [number, number][]) => {
//   const higherNumber = numbers.reduce((acc, [a, b]) => (b - a > acc ? b - a : acc), 0)
//   return higherNumber
// }

// const getTime = (number: number) => {
//   const hours = Math.floor(number / 3600)
//   const minutes = Math.floor((number % 3600) / 60)
//   const seconds = Math.floor(number % 60)

//   return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
// }
